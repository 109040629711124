<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 70px">
      <main class="page-content">
        <div class="container-fluid">
          <ul
            class="breadcrumb ProximaNovaRegular"
            style="margin-left: 52px; margin-top: 12px"
          >
            <li>
              <span>Solutions</span>
            </li>
            <li>
              <span>AIMO</span>
            </li>
            <li class="ProximaNovaBold">Marketing channel form</li>
          </ul>
        </div>
        <div class="container mb-5" style="margin-top: 28px">
          <div
            class="
              col-sm-12 col-xs-12 col-md-12 col-lg-12
              d-flex
              justify-center
            "
          >
            <div class="col-sm-12 col-md-7 col-xs-12 col-lg-7">
              <w-multi-step-bar
                :stepName="stepName"
                :activeState="activeFrom"
              ></w-multi-step-bar>
            </div>
          </div>
        </div>
        <div
          class="container-fluid"
          style="margin-top: 80px; padding-left: 52px"
          v-if="activeFrom === 1"
        >
          <w-tab
            :tabName="activeTab"
            :tabs="tabs"
            @activeTab="changeTab($event)"
          >
          </w-tab>
        </div>
        <div class="w-100" v-if="activeFrom === 1">
          <div
            class="container-fluid tab-panel"
            style="margin-top: 36px; padding-left: 52px"
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{ showConnectors: activeTab === tab.category }"
          >
            <div
              class="card w-100"
              v-for="(tabContent, index) in tab.tabContent"
              :key="index"
            >
              <div
                class="card-header font-large ProximaNovaBold"
                style="
                  padding: 0.75rem 1.5rem;
                  background: transparent;
                  border: 0px !important;
                "
              >
                {{ tabContent.title }}
              </div>
              <div class="card-body">
                <div>
                  <w-connector
                    :addActiveClass="false"
                    :connectors="tabContent.connectors"
                    :title="tabContent.title"
                    :selectedConnectorsList="selectedConnectors"
                    @addPlatform="addPlatform($event)"
                  >
                  </w-connector>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100" v-if="activeFrom === 2">
          <b-container fluid>
            <b-row>
              <!-- Campaign Type -->
              <div class="col-12">
                <div class="card mt-5 p-5">
                  <StripChart title="Media Mix" :chartData="mediaMixData" />
                </div>
              </div>
              <!-- Social Bar Graph -->
              <div class="col-6">
                <div class="card mt-5 p-4">
                  <BarChart :chartData="socialBarData" />
                </div>
              </div>
              <!-- Sales - Region wise -->
              <div class="col-12 col-lg-6 mt-5">
                <div class="card pb-5 pb-lg-0 h-100">
                  <div class="dropdown-wrapper">
                    <w-white-dropdown
                      :options="regionDropdown"
                      :placeHolder="'Select'"
                      :selectedOption="selectedRegion"
                      :labelText="''"
                      @input="selectRegion($event)"
                      width="206px"
                    ></w-white-dropdown>
                  </div>
                  <div class="p-4">
                    <ColumnChart :chartData="regionWiseData" />
                  </div>
                </div>
              </div>
              <!-- Sales - Product wise -->
              <div class="col-12 col-lg-12 mt-5">
                <div class="card pb-5 pb-lg-0 h-100">
                  <div class="p-4">
                    <LineChart :chartData="lineChartData" />
                  </div>
                </div>
              </div>
              <!-- Sales - Channel wise -->
              <div class="col-12 col-lg-12 mt-5">
                <div class="card pb-5 pb-lg-0 h-100">
                  <div class="p-4">
                    <ColumnChart :chartData="channelWiseData" />
                  </div>
                </div>
              </div>
            </b-row>
          </b-container>
        </div>
        <div class="w-100 py-4" v-if="activeFrom === 3">
          <div
            class="container-fluid"
            style="margin-top: 36px; padding-left: 52px"
          >
            <div class="col-md-12 col-lg-12">
              <div class="card w-100 kpi-box">
                <div class="row">
                  <div class="col-md-4 colo-lg-4">
                    <div class="w-100 img-section">
                      <img
                        class="img img-responsive"
                        src="@/assets/Icons/group-21.png"
                        alt="KPI"
                        height="100%"
                      />
                    </div>
                  </div>
                  <div class="col-md-8 col-lg-8">
                    <div class="w-100 row" style="height: 100%">
                      <div
                        class="w-100 d-flex px-5"
                        style="
                          height: 35%;
                          justify-content: start;
                          align-items: flex-end;
                        "
                      >
                        <span class="ProximaNovaBold" style="font-size: x-large"
                          >Define KPI</span
                        >
                      </div>
                      <div
                        class="col-md-6 colo-lg-6 d-flex justify-center"
                        style="height: 40%"
                      >
                        <div class="w-100 p-4 pr-5">
                          <w-dropdown
                            :options="kpidropdown"
                            :placeHolder="'Select'"
                            :labelText="'KPI'"
                            class="mr-3"
                            @input="selectedKPi($event)"
                          ></w-dropdown>
                        </div>
                      </div>
                      <div
                        class="col-md-6 colo-lg-6 d-flex justify-center"
                        style="height: 40%"
                      >
                        <div class="w-100 p-4 pr-5">
                          <w-dropdown
                            :options="marketDropdown"
                            :labelText="'Market Type'"
                            class="mr-5"
                            @input="selectMarket($event)"
                          ></w-dropdown>
                        </div>
                      </div>
                      <div class="w-100 pr-5" style="height: 25%">
                        <div
                          class="row mr-2 pr-2"
                          style="float: right"
                          :class="{ changeColor: isDisable }"
                        >
                          <w-button
                            :buttonLoader="'normal'"
                            :buttonText="'Create Plan'"
                            :class="{ resetPasswordLink: isDisable }"
                            :isDisable="isDisable"
                            @buttonClicked="createPlan"
                          >
                          </w-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid w-100" v-if="activeFrom < 3">
          <div class="row pt-5 pb-5 mr-2" style="float: right">
            <w-button
              :buttonLoader="'normal'"
              :buttonText="'Continue'"
              :isDisable="disableConnectors"
              :class="{ resetPasswordLink: disableConnectors }"
              @buttonClicked="nextToSource"
            >
            </w-button>
          </div>
        </div>
        <!--<div class="w-100 d-flex justify-center align-center">
            <Learning />
        </div>-->
        <solution-modal ref="solutionModal" @progressDone="doneProgress"/>
        <!-- <modal
          name="newWelcomePage"
          :width="388"
          class="welcome--modal"
          @closed="hideWelcomePage"
          :clickToClose="false"
          v-show="isStartNewModal"
        >
          <div class="">
            <div class="w-100 modal-scroll">
              <div class="row modal-padding">
                <div class="col p-0 welcome ml-4 mt-4 align-start">
                  <img
                    src="@/assets/Icons/group-21.png"
                    alt
                    class="welcome--image"
                  />
                </div>
                <div class="col p-0 intro__content">
                  <p class="Welcome-to-Data-Poem ProximaNovaRegular">
                    Digital Media
                  </p>
                  <div class="input-group">
                    <div class="form-outline">
                      <input
                        type="search"
                        id="form1"
                        class="form-control search-box ProximaNovaBold"
                        placeholder="Search"
                      />
                    </div>
                    <button type="button" class="btn btn-primary search-button">
                      <i class="fas fa-search fa-2x"></i>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div
                      class="container-fluid tab-panel"
                      style="margin-top: 36px; padding: 0px"
                      v-for="(tab, index) in tabs"
                      :key="index"
                      :class="{ showConnectors: activeTab === tab.category }"
                    >
                      <div
                        class="card1 w-100"
                        v-for="(tabContent, index) in tab.tabContent"
                        :key="index"
                      >
                        <div class="card-body1">
                          <div class="modal-connectors">
                            <w-connector
                              :addActiveClass="false"
                              :connectors="tabContent.connectors"
                              :title="tabContent.title"
                              :selectedConnectorsList="selectedConnectors"
                              @addPlatform="addPlatform($event)"
                            >
                            </w-connector>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row w-100 cancel-btn-section">
                    <div
                      class="
                        col-sm-6 col-md-3
                        offset-md-3
                        text-right
                        d-flex
                        justify-content-end
                      "
                    >
                      <span
                        class="start-from-fresh mt-4"
                        @click="hideWelcomePage"
                        >Cancel</span
                      >
                    </div>
                    <div class="col-sm-6 col-md-6 cancel-btn-section">
                      <button
                        color="primary isi"
                        class="btn-effect-intro-modal"
                        size="button--size-m"
                        @click="gotoPlatform($event)"
                      >
                        <span class="btn-text">Continue </span>
                        <i
                          class="fas fa-arrow-right"
                          style="align-items: center"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </modal> -->
      </main>
    </div>
  </div>
</template>
<script>
import Connector from "@/components/AIMO-AI/Connector.vue";
import MultiStepProgressBar from "@/components/Solutions/MultiStepProgressBar.vue";
import Button from "@/components/Profile/Button.vue";
import Tab from "@/components/Solutions/Tab.vue";
import Dropdown from "@/widgets/Dropdown.vue";
import StripChart from "@/components/Chart/StripChart.vue";
import BarChart from "@/components/Chart/BarChart.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import LineChart from "@/components/Chart/LineChart.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import SolutionModal from "@/components/Modal/SolutionModal.vue";


export default {
  props: [],
  components: {
    "w-connector": Connector,
    "w-multi-step-bar": MultiStepProgressBar,
    "w-button": Button,
    "w-tab": Tab,
    "w-dropdown": Dropdown,
    "w-white-dropdown": WhiteDropdown,
    StripChart,
    BarChart,
    ColumnChart,
    LineChart,
    SolutionModal
  },
  data() {
    return {
      isShow: false,
      regionDropdown: [
        { text: "City wise", id: "city wise" },
        { text: "State wise", id: "state wise" },
        { text: "Country wise", id: "country wise" },
      ],
      kpidropdown: [
        { text: "Sales", id: "sales" },
        { text: "Leads", id: "leads", disable: false },
        { text: "Profit Margin", id: "Profit Margin", disable: false },
        { text: "Volume", id: "volume", disable: false },
        { text: "Cart Value", id: "Cart Value", disable: false },
      ],
      marketDropdown: [
        { text: "All India", id: "allindia" },
        { text: "USA", id: "USA" },
        { text: "HSM", id: "hsm", disable: false },
        { text: "KER", id: "ker", disable: false },
        { text: "KA", id: "KA", disable: false },
      ],
      selectedMarket: "",
      selectedKPI: "",
      activeTab: "Digital Media",
      activeFrom: 1,
      orderSubCategory: [],
      selectedConnectors: [],
      categorizedSelectedConnectors: [],
      stepName: ["Data source", "Input data summary", "KPI"],
      tabs: [
        {
          category: "Digital Media",
          tabContent: [
            {
              title: "Pre-loaded",
              connectors: [
                {
                  name: "Path Matics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adclarity",
                  image: "adobe-anal",
                  role: "Adclarity",
                },
                {
                  name: "Nieslen",
                  image: "instagram",
                  role: "Nieslen",
                },
              ],
            },
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
          ],
        },
        {
          category: "CRMs",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                {
                  name: "Publishers",
                  image: "appsFlyer",
                  role: "DM Publishers",
                },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
            {
              title: "App Anaytics",
              connectors: [
                { name: "App Flyer", image: "appsFlyer", role: "AA App Flyer" },
              ],
            },
          ],
        },
        {
          category: "Sponsorship",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                {
                  name: "Publishers",
                  image: "appsFlyer",
                  role: "DM Publishers",
                },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
            {
              title: "App Anaytics",
              connectors: [
                { name: "App Flyer", image: "appsFlyer", role: "AA App Flyer" },
              ],
            },
          ],
        },
        {
          category: "BillBoard",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                {
                  name: "Publishers",
                  image: "appsFlyer",
                  role: "DM Publishers",
                },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
            {
              title: "App Anaytics",
              connectors: [
                { name: "App Flyer", image: "appsFlyer", role: "AA App Flyer" },
              ],
            },
          ],
        },
        {
          category: "Outdoor",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                {
                  name: "Publishers",
                  image: "appsFlyer",
                  role: "DM Publishers",
                },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
            {
              title: "App Anaytics",
              connectors: [
                { name: "App Flyer", image: "appsFlyer", role: "AA App Flyer" },
              ],
            },
          ],
        },
        {
          category: "Public relation",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                {
                  name: "Publishers",
                  image: "appsFlyer",
                  role: "DM Publishers",
                },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
            {
              title: "App Anaytics",
              connectors: [
                { name: "App Flyer", image: "appsFlyer", role: "AA App Flyer" },
              ],
            },
          ],
        },
        {
          category: "Main Line Media",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                {
                  name: "Publishers",
                  image: "appsFlyer",
                  role: "DM Publishers",
                },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
            {
              title: "App Anaytics",
              connectors: [
                { name: "App Flyer", image: "appsFlyer", role: "AA App Flyer" },
              ],
            },
          ],
        },
        {
          category: "KPI Media",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                {
                  name: "Publishers",
                  image: "appsFlyer",
                  role: "DM Publishers",
                },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
            {
              title: "App Anaytics",
              connectors: [
                { name: "App Flyer", image: "appsFlyer", role: "AA App Flyer" },
              ],
            },
          ],
        },
      ],
      mediaMixData: [
        {
          label: "Digital",
          value: 35,
          backgroundColor: "#67e8ff",
        },
        {
          label: "Television",
          value: 23,
          backgroundColor: "#b5a9ff",
        },
        {
          label: "Content",
          value: 5,
          backgroundColor: "#faacfe",
        },
        {
          label: "Radio",
          value: 9,
          backgroundColor: "#81f89e",
        },
        {
          label: "Newspaper",
          value: 15,
          backgroundColor: "#ffa2a2",
        },
        {
          label: "Print",
          value: 13,
          backgroundColor: "#ffdd7c",
        },
      ],
      socialBarData: {
        height: 400,
        // scrollableHeight: 500,
        colors: [
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#fdbfff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        title: {
          text: "Sales - Year wise",
          align: "left",
          margin: 20,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        xAxis: {
           max: 3,
          categories: ["2017", "2016", "2015", "2014", "2013", "2012", "2011"],
          title: {
            text: null,
          },
          labels: {
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
          scrollbar: {
          enabled: true,
          barBackgroundColor: '#F7FAFF',
          barBorderRadius: 7,
          barBorderWidth: 0,
          buttonBackgroundColor: 'white',
          buttonBorderWidth: 0,
          buttonArrowColor: 'white',
          buttonBorderRadius: 0,
          rifleColor: '#F7FAFF',
          trackBackgroundColor: 'white',
          trackBorderWidth: 0,
          trackBorderColor: '#FAFAFA',
          trackBorderRadius: 0,
          width:50
        },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
            labels: {
              style: {
                color: "#a9b6cd",
                fontSize: "12px",
                fontFamily: "ProximaNovaRegular",
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label;
              },
            },
            gridLineDashStyle: "shortdash",
            tickInterval: 5000,
          },
        ],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
            pointPadding: 0.3,
            colorByPoint: true,
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Sales - Year wise",
            data: [7800, 6000, 4000, 5043, 2330, 5200, 12200],
          },
        ],
      },
      regionWiseData: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "Metrics - Overall Channels",
            data: [7500, 12000, 3000, 15000, 8000, 11000, 17000, 5000, 4000],
          },
        ],
        title: {
          text: "Sales - Region wise ",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          max:4,
          categories: [
            "Uttar Pradesh",
            "Tamil Nadu",
            "Maharastra",
            "Karnataka",
            "Kerla",
            "Gujrat",
            "Andhra Pradesh",
            "Haryana",
            "Rajasthan",
          ],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: '#F7FAFF',
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: 'white',
            buttonBorderWidth: 0,
            buttonArrowColor: 'white',
            buttonBorderRadius: 0,
            rifleColor: '#F7FAFF',
            trackBackgroundColor: 'white',
            trackBorderWidth: 0,
            trackBorderColor: '#FAFAFA',
            trackBorderRadius: 0,
            width:50
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      lineChartData: {
        series: [
          {
            data: [
              { x: Date.UTC(2017, 1, 31), y: 60 },
              { x: Date.UTC(2017, 2, 31), y: 33 },
              { x: Date.UTC(2017, 3, 31), y: 25 },
              { x: Date.UTC(2017, 4, 31), y: 4 },
              { x: Date.UTC(2017, 5, 31), y: 51 },
              { x: Date.UTC(2017, 6, 31), y: 40 },
              { x: Date.UTC(2017, 7, 31), y: 29 },
              { x: Date.UTC(2017, 8, 31), y: 71 },
              { x: Date.UTC(2017, 9, 31), y: 23 },
              { x: Date.UTC(2018, 1, 31), y: 1 },
              { x: Date.UTC(2018, 2, 31), y: 3 },
              { x: Date.UTC(2018, 3, 31), y: 15 },
              { x: Date.UTC(2018, 4, 31), y: 4 },
              { x: Date.UTC(2018, 5, 31), y: 15 },
              { x: Date.UTC(2018, 6, 31), y: 14 },
              { x: Date.UTC(2018, 7, 31), y: 23 },
              { x: Date.UTC(2018, 8, 31), y: 42 },
              { x: Date.UTC(2018, 9, 31), y: 25 },
              { x: Date.UTC(2019, 1, 31), y: 1 },
              { x: Date.UTC(2019, 2, 31), y: 3 },
              { x: Date.UTC(2019, 3, 31), y: 15 },
              { x: Date.UTC(2019, 4, 31), y: 4 },
              { x: Date.UTC(2019, 5, 31), y: 15 },
              { x: Date.UTC(2019, 6, 31), y: 14 },
              { x: Date.UTC(2019, 7, 31), y: 23 },
              { x: Date.UTC(2019, 8, 31), y: 42 },
              { x: Date.UTC(2019, 9, 31), y: 25 },
            ],
            marker: {
              symbol: "circle",
              radius: 10,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "ABC",
          },
          {
            data: [
              { x: Date.UTC(2017, 1, 31), y: 60 },
              { x: Date.UTC(2017, 2, 31), y: 33 },
              { x: Date.UTC(2017, 3, 31), y: 25 },
              { x: Date.UTC(2017, 4, 31), y: 4 },
              { x: Date.UTC(2017, 5, 31), y: 51 },
              { x: Date.UTC(2017, 6, 31), y: 40 },
              { x: Date.UTC(2017, 7, 31), y: 29 },
              { x: Date.UTC(2017, 8, 31), y: 71 },
              { x: Date.UTC(2017, 9, 31), y: 23 },
              { x: Date.UTC(2018, 1, 31), y: 90 },
              { x: Date.UTC(2018, 2, 31), y: 30 },
              { x: Date.UTC(2018, 3, 31), y: 5 },
              { x: Date.UTC(2018, 4, 31), y: 40 },
              { x: Date.UTC(2018, 5, 31), y: 10 },
              { x: Date.UTC(2018, 6, 31), y: 4 },
              { x: Date.UTC(2018, 7, 31), y: 70 },
              { x: Date.UTC(2018, 8, 31), y: 57 },
              { x: Date.UTC(2018, 9, 31), y: 20 },
              { x: Date.UTC(2019, 1, 31), y: 10 },
              { x: Date.UTC(2019, 2, 31), y: 3 },
              { x: Date.UTC(2019, 3, 31), y: 55 },
              { x: Date.UTC(2019, 4, 31), y: 4 },
              { x: Date.UTC(2019, 5, 31), y: 1 },
              { x: Date.UTC(2019, 6, 31), y: 40 },
              { x: Date.UTC(2019, 7, 31), y: 27 },
              { x: Date.UTC(2019, 8, 31), y: 7 },
              { x: Date.UTC(2019, 9, 31), y: 2 },
            ],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "DEF",
          },
          {
            data: [
              { x: Date.UTC(2017, 1, 31), y: 10 },
              { x: Date.UTC(2017, 2, 31), y: 3 },
              { x: Date.UTC(2017, 3, 31), y: 55 },
              { x: Date.UTC(2017, 4, 31), y: 4 },
              { x: Date.UTC(2017, 5, 31), y: 1 },
              { x: Date.UTC(2017, 6, 31), y: 40 },
              { x: Date.UTC(2017, 7, 31), y: 27 },
              { x: Date.UTC(2017, 8, 31), y: 7 },
              { x: Date.UTC(2017, 9, 31), y: 2 },
              { x: Date.UTC(2018, 1, 31), y: 60 },
              { x: Date.UTC(2018, 2, 31), y: 33 },
              { x: Date.UTC(2018, 3, 31), y: 25 },
              { x: Date.UTC(2018, 4, 31), y: 4 },
              { x: Date.UTC(2018, 5, 31), y: 51 },
              { x: Date.UTC(2018, 6, 31), y: 40 },
              { x: Date.UTC(2018, 7, 31), y: 29 },
              { x: Date.UTC(2018, 8, 31), y: 71 },
              { x: Date.UTC(2018, 9, 31), y: 23 },
              { x: Date.UTC(2019, 1, 31), y: 90 },
              { x: Date.UTC(2019, 2, 31), y: 30 },
              { x: Date.UTC(2019, 3, 31), y: 5 },
              { x: Date.UTC(2019, 4, 31), y: 40 },
              { x: Date.UTC(2019, 5, 31), y: 10 },
              { x: Date.UTC(2019, 6, 31), y: 4 },
              { x: Date.UTC(2019, 7, 31), y: 70 },
              { x: Date.UTC(2019, 8, 31), y: 57 },
              { x: Date.UTC(2019, 9, 31), y: 20 },
            ],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "GHI",
          },
          {
            data: [
              { x: Date.UTC(2017, 1, 31), y: 90 },
              { x: Date.UTC(2017, 2, 31), y: 30 },
              { x: Date.UTC(2017, 3, 31), y: 5 },
              { x: Date.UTC(2017, 4, 31), y: 40 },
              { x: Date.UTC(2017, 5, 31), y: 10 },
              { x: Date.UTC(2017, 6, 31), y: 4 },
              { x: Date.UTC(2017, 7, 31), y: 70 },
              { x: Date.UTC(2017, 8, 31), y: 57 },
              { x: Date.UTC(2017, 9, 31), y: 20 },
              { x: Date.UTC(2018, 1, 31), y: 60 },
              { x: Date.UTC(2018, 2, 31), y: 33 },
              { x: Date.UTC(2018, 3, 31), y: 25 },
              { x: Date.UTC(2018, 4, 31), y: 4 },
              { x: Date.UTC(2018, 5, 31), y: 51 },
              { x: Date.UTC(2018, 6, 31), y: 40 },
              { x: Date.UTC(2018, 7, 31), y: 29 },
              { x: Date.UTC(2018, 8, 31), y: 71 },
              { x: Date.UTC(2018, 9, 31), y: 23 },
              { x: Date.UTC(2019, 1, 31), y: 90 },
              { x: Date.UTC(2019, 2, 31), y: 30 },
              { x: Date.UTC(2019, 3, 31), y: 5 },
              { x: Date.UTC(2019, 4, 31), y: 40 },
              { x: Date.UTC(2019, 5, 31), y: 10 },
              { x: Date.UTC(2019, 6, 31), y: 4 },
              { x: Date.UTC(2019, 7, 31), y: 70 },
              { x: Date.UTC(2019, 8, 31), y: 57 },
              { x: Date.UTC(2019, 9, 31), y: 20 },
            ],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "JKL",
          },
        ],
        title: {
          text: "Sales - Product wise",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemMarginTop: 30,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "",
            style: {
              color: "#8394b4",
            },
          },
          gridLineDashStyle: "shortdash",
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "12px",
              fontFamily: "ProximaNovaRegular",
            },
            formatter: function () {
              var label = this.axis.defaultLabelFormatter.call(this);
              return label;
            },
          },
        },
        xAxis: {
          title: {
            text: "",
            style: {
              color: "#8394b4",
            },
          },
          units: [
            ["month", [3, 6]],
            ["year", [1]],
          ],
          type: "datetime",
          labels: {
            format: "{value:Q%q-%Y}",
            style: {
              color: "#9aafd4",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        colors: ["#050505", "#faacfe", "#b5a9ff", "#67e8ff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function() {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
      },
      channelWiseData: {
        data: [
          {
            name: "E-commerce",
            data: [25000000, 20000000, 110000000],
          },
          {
            name: "Grocery Retail",
            data: [175000000, 190000000, 180000000],
          },
          {
            name: "Mordern Trade",
            data: [155000000, 105000000, 199000000],
          },
          {
            name: "Rural",
            data: [55000000, 55000000, 105000000],
          },
          {
            name: "Small Stores",
            data: [55000000, 85000000, 105000000],
          },
        ],
        title: {
          text: "Sales - Channel wise ",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#faacfe", "#67e8ff", "#ffdd7c", "#b5a9ff", "#ffa2a2"],
        legend: {
          align: "left",
          verticalAlign: "bottom",
          layout: "horizontal",
          itemMarginTop: 30,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        xAxis: {
          categories: ["2017", "2018", "2019"],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.3,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      selectedRegion: {},
    };
  },
  computed: {
    isDisable() {
      if (
        this.selectedMarket !== "Select" &&
        this.selectedKPI !== "Select" &&
        this.selectedMarket !== "" &&
        this.selectedKPI !== ""
      ) {
        return false;
      }
      return true;
    },
    disableConnectors() {
      if (this.activeFrom === 1) {
        if (this.selectedConnectors.length > 0) {
          return false;
        }
      } else if (this.activeFrom === 2) {
        return false;
      }
      return true;
    },
    isStartNewModal() {
      return this.isShow;
    },
  },
  methods: {
    selectRegion(e) {
      this.selectedRegion = e;
    },
    createPlan() {
      // this.isShow = true;
      // console.log("create Plan");
      // this.$modal.show("newWelcomePage");
        this.$refs.solutionModal.open();
    },
    hideWelcomePage() {
      this.$modal.hide("newWelcomePage");
    },
    showWelcomePage() {
      this.isShow = true;
      this.$modal.show("newWelcomePage");
    },
    doneProgress() {
      this.$router.push("/solutions/AIMO/results");
    },
    selectedKPi(e) {
      if (this.selectedKPI != e) {
        this.selectedKPI = e;
        console.log(e);
      }
    },
    selectMarket(e) {
      if (e === this.selectedMarket || e === "Select") {
        return;
      }
      sessionStorage.setItem("kpiMarket", JSON.stringify(e));
      this.selectedMarket = e;
    },
    orderSubCategoryMethod() {
      for (var i = 0; i < this.categorizedSelectedConnectors.length; i++) {
        var tabContent = [];
        for (
          var j = 0;
          j < this.categorizedSelectedConnectors[i].tabContent.length;
          j++
        ) {
          if (
            tabContent.find(
              (subTitle) =>
                subTitle.title ===
                this.categorizedSelectedConnectors[i].tabContent[j].title
            ) === undefined
          ) {
            tabContent.push({
              title: this.categorizedSelectedConnectors[i].tabContent[j].title,
              connectors: [
                {
                  name: this.categorizedSelectedConnectors[i].tabContent[j]
                    .connectorName,
                  image:
                    this.categorizedSelectedConnectors[i].tabContent[j]
                      .className,
                },
              ],
            });
          } else {
            for (var k = 0; k < tabContent.length; k++) {
              if (
                tabContent[k].title ===
                this.categorizedSelectedConnectors[i].tabContent[j].title
              ) {
                tabContent[k].connectors.push({
                  name: this.categorizedSelectedConnectors[i].tabContent[j]
                    .connectorName,
                  image:
                    this.categorizedSelectedConnectors[i].tabContent[j]
                      .className,
                });
              }
            }
          }
          if (
            j ===
            this.categorizedSelectedConnectors[i].tabContent.length - 1
          ) {
            this.categorizedSelectedConnectors[i].tabContent = tabContent;
          }
        }
        if (i === this.categorizedSelectedConnectors.length - 1) {
          this.activeTab = this.categorizedSelectedConnectors[0].category;
        }
      }
    },
    nextToSource() {
      if (this.activeFrom === 2) {
        this.activeFrom = 3;

        return;
      }
      this.activeFrom = 2;
      this.categorizedSelectedConnectors = [];
      for (var i = 0; i < this.selectedConnectors.length; i++) {
        if (
          this.categorizedSelectedConnectors.find(
            (obj) => obj.category === this.selectedConnectors[i].category
          ) === undefined
        ) {
          this.categorizedSelectedConnectors.push({
            category: this.selectedConnectors[i].category,
            tabContent: [this.selectedConnectors[i]],
          });
        } else {
          for (var j = 0; j < this.categorizedSelectedConnectors.length; j++) {
            if (
              this.categorizedSelectedConnectors[j].category ===
              this.selectedConnectors[i].category
            ) {
              this.categorizedSelectedConnectors[j].tabContent.push(
                this.selectedConnectors[i]
              );
            }
          }
        }
        if (i === this.selectedConnectors.length - 1) {
          this.orderSubCategoryMethod();
        }
      }
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    addActiveTab() {},
    addPlatform(obj) {
      var platformobj = obj.obj;
      var title = obj.title;
      if (
        this.selectedConnectors.find(
          (connector) => connector.role === platformobj.role
        ) != undefined
      ) {
        for (var i = 0; i < this.selectedConnectors.length; i++) {
          if (this.selectedConnectors[i].role == platformobj.role) {
            this.selectedConnectors.splice(i, 1);
          }
        }
      } else {
        this.selectedConnectors.push({
          title: title,
          category: this.activeTab,
          connectorName: platformobj.name,
          className: platformobj.image,
          role: platformobj.role,
        });
      }
    },
  },
  created() {},
};
</script>
<style scoped>
.dropdown-wrapper {
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 9;
}
.changeColor .disableButton {
  color: #fff !important;
}
.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}
.action {
  color: #000;
}
.container {
  height: 100%;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}
.step-indicator .stepactive::after {
  background: #050505;
}
.tab-panel.showConnectors {
  display: block;
}
.tab-panel {
  display: none;
}
.card {
  display: block;
}
.search-box {
  width: 450px;
  height: 60px;
  font-size: 16px;
}
.search-button {
  width: 50px;
  background-color: #050505;
}
/deep/.welcome--modal .vm--modal {
  width: 75% !important;
}
/deep/ .modal-connectors .connector {
  height: 80px;
  width: 243px;
  margin: 11px 11px 11px 0;
}
.welcome {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  align-content: flex-start;
}
.modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}

.modal-body::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e8edf5;
}
span.start-from-fresh.mt-4 {
  width: 59px;
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  float: left;
}
.btn-effect-intro-modal {
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  width: 175px !important;
  height: 60px !important;
}
.img-section {
  padding: 3rem;
}
.modal-body {
  overflow-y: scroll;
  width: 525px;
  height: 250px;
  padding: 0;
}
.input-group {
  margin-bottom: 15px;
}
@media screen and (max-width: 320px) {
  .welcome--modal .vm--modal {
    width: 85% !important;
  }
}
@media screen and (max-width: 640px) {
  .welcome--modal .vm--modal {
    width: 85% !important;
  }
  img.img.img-responsive {
    margin: auto;
  }
  .Welcome-to-Data-Poem {
    text-align: center;
  }
  .search-box {
    width: 100%;
    height: 60px;
    font-size: 16px;
    padding-left: 15px;
    margin-left: 35px;
  }
  .w-100.modal-scroll {
    height: 700px;
    overflow-y: scroll;
  }
  .col-sm-6.col-md-3.offset-md-3.text-right.d-flex.justify-content-end {
    width: 40%;
  }
  .cancel-btn-section {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  p.Welcome-to-Data-Poem {
    text-align: center;
  }
  .kpi-box {
    height: 800px;
  }
  .search-box {
    width: 100%;
    height: 60px;
    font-size: 16px;
    padding-left: 15px;
    margin-left: 35px;
  }
  .modal-body {
    overflow-y: scroll;
    width: 100%;
    height: 550px;
    padding: 0 5rem;
  }
  .w-100.modal-scroll {
    height: 700px;
    overflow-y: scroll;
  }
  /deep/.modal-connectors .connector {
    width: 215px !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .kpi-box {
    height: 300px;
  }
  .Welcome-to-Data-Poem {
    text-align: center;
  }
  .input-group {
    margin-left: 13rem;
  }
  /deep/.modal-body {
    overflow-y: scroll !important;
    width: 525px;
    height: 250px;
    padding: 0;
    margin-left: 8rem;
  }
}
</style>
